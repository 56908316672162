<template>
  <div>
    <!-- Overlay -->
    <div
      v-if="open"
      class="fixed inset-0 bg-black bg-opacity-50 z-10 transition-opacity md:hidden"
      @click="toggleMenu"
    />

    <div
      class="max-w-full md:max-w-[960px] mx-auto px-10 md:px-10 py-3 border-b border-gray-200 flex justify-between items-center"
    >
      <!-- Logo -->
      <div class="flex items-center gap-4">
        <button class="z-30 md:hidden" @click="open = !open">
          <!-- Hamburger Icon -->
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
        <div>
          <NuxtLink :to="isAuthenticated ? '/vet/welcome' : '/'">
            <!-- image -->
            <img
              src="public/images/brand/logo-text-medgreen.png"
              alt="PetCall Logo"
              class="w-20"
            />
          </NuxtLink>
        </div>
      </div>

      <!-- Navigation Links and Buttons -->
      <div class="flex items-center justify-between">
        <nav class="hidden md:flex gap-9 items-center">
          <template v-if="!isAuthenticated">
            <NuxtLink
              to="/"
              class="text-sm font-normal text-neutral-900 hover:underline"
            >
              {{ $t('vetHeader.howItWorks') }}
            </NuxtLink>
            <NuxtLink
              to="/about"
              class="text-sm font-normal text-neutral-900 hover:underline"
            >
              {{ $t('vetHeader.whyPetVet') }}
            </NuxtLink>
          </template>

          <LanguageSwitcher />

          <template v-if="isAuthenticated">
            <NuxtLink
              to="/vet/meetings"
              class="text-sm font-normal text-neutral-900 hover:underline"
            >
              {{ $t('vetHeader.meetings') }}
            </NuxtLink>

            <Menu as="div" class="relative inline-block text-left">
              <div>
                <MenuButton
                  class="flex items-center text-sm font-normal text-secondary hover:secondary-light"
                >
                  <UserIcon
                    class="h-7 w-7 hover:opacity-80 rounded-full border-2 border-secondary"
                    aria-hidden="true"
                  />
                </MenuButton>
              </div>

              <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
              >
                <MenuItems
                  class="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-50"
                >
                  <div class="px-1 py-1">
                    <MenuItem v-slot="{ active }">
                      <NuxtLink
                        to="/vet/profile"
                        :class="[
                          active ? 'bg-secondary text-white' : 'text-gray-900',
                          'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                        ]"
                      >
                        <span
                          :active="active"
                          class="mr-2 h-5 w-5 text-secondary"
                          aria-hidden="true"
                        />
                        {{ $t('vetHeader.profile') }}
                      </NuxtLink>
                    </MenuItem>
                  </div>
                  <div class="px-1 py-1">
                    <MenuItem v-slot="{ active }">
                      <button
                        :class="[
                          active ? 'bg-secondary text-white' : 'text-gray-900',
                          'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                        ]"
                        @click="logOut"
                      >
                        <span class="mr-2 h-5 w-5 text-secondary" />
                        {{ $t('vetHeader.logOut') }}
                      </button>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </template>
        </nav>

        <div class="flex gap-2 ml-auto">
          <!-- Added ml-auto to push buttons to the right and maintain spacing -->
          <div>&nbsp;&nbsp;&nbsp;</div>
          <template v-if="!isAuthenticated">
            <NuxtLink to="/signup">
              <button
                class="px-2 py-2 bg-secondary text-white text-xs rounded-xl hover:bg-secondary-light"
              >
                {{ $t('vetHeader.signUp') }}
              </button>
            </NuxtLink>
            <NuxtLink to="/login">
              <button
                class="px-2 py-2 bg-primary text-white text-xs rounded-xl hover:bg-primary-light"
              >
                {{ $t('vetHeader.logIn') }}
              </button>
            </NuxtLink>
          </template>
        </div>
      </div>
    </div>

    <!-- Slide-out Menu Below Navigation Header -->
    <transition
      name="slide-out-menu"
      enter-active-class="ease-out duration-300"
      leave-active-class="ease-in duration-300"
    >
      <div
        v-if="open"
        class="slide-out-menu fixed md:relative top-[65px] inset-x-0 w-full md:w-auto bg-white z-20"
      >
        <!-- Close Icon at the top right corner -->
        <button class="absolute top-0 right-0 m-4" @click="toggleMenu">
          <svg
            class="h-6 w-6 text-gray-700"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <nav class="flex flex-col p-4">
          <NuxtLink
            href="/"
            class="mb-4 hover:underline hover:text-secondary"
            @click="toggleMenu"
          >
            {{ $t('vetHeader.howItWorks') }}
          </NuxtLink>
          <NuxtLink
            to="/about"
            class="mb-4 hover:underline hover:text-secondary"
            @click="toggleMenu"
          >
            {{ $t('vetHeader.whyPetVet') }}
          </NuxtLink>

          <template v-if="isAuthenticated">
            <NuxtLink
              to="/vet/meetings"
              class="mb-4 hover:underline hover:text-secondary"
              @click="toggleMenu"
            >
              {{ $t('vetHeader.meetings') }}
            </NuxtLink>
            <NuxtLink
              class="mb-4 hover:underline cursor-pointer text-red-400/90 hover:text-red-600"
              @click="logOut"
            >
              {{ $t('vetHeader.logOut') }}
            </NuxtLink>
          </template>
          <template v-else>
            <NuxtLink
              to="/signup"
              class="mb-4 hover:underline hover:text-secondary"
              @click="toggleMenu"
            >
              {{ $t('vetHeader.signUp') }}
            </NuxtLink>
            <NuxtLink
              to="/login"
              class="mb-4 hover:underline hover:text-secondary"
              @click="toggleMenu"
            >
              {{ $t('vetHeader.logIn') }}
            </NuxtLink>
          </template>
        </nav>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useRouter } from 'vue-router'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { ChevronDownIcon, UserIcon } from '@heroicons/vue/20/solid'

const router = useRouter()
const authStore = useAuthStore()

const open = ref(false)

// Method to toggle the menu open/closed
const toggleMenu = () => {
  open.value = !open.value
}
const isAuthenticated = computed(() => authStore.isAuthenticated)

// Method to sign out the user
const logOut = () => {
  authStore.logout()
  router.push({ name: 'login' })
}
</script>

<style scoped>
/* Slide-out menu transition styles */
.slide-out-menu-enter-active,
.slide-out-menu-leave-active {
  transition:
    transform 0.3s,
    opacity 0.3s;
}
.slide-out-menu-enter-from,
.slide-out-menu-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}
.slide-out-menu-enter-to,
.slide-out-menu-leave-from {
  opacity: 1;
  transform: translateX(0);
}

.fixed-action-bar {
  background-color: #ffffff; /* White background */
  padding: 1rem; /* Padding around the buttons */
  box-shadow:
    0 -4px 6px -1px rgba(0, 0, 0, 0.1),
    0 -2px 4px -1px rgba(0, 0, 0, 0.06); /* Shadow on top */
}

/* Style for the call action buttons */
.call-action-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Style for icons */
.call-action-icon {
  width: 3rem; /* 48px */
  height: 3rem; /* 48px */
}

/* Style for the text under icons */
.call-action-text {
  margin-top: 0.5rem; /* 8px */
  font-size: 0.75rem; /* 12px */
  color: #4a5568; /* Gray text */
}

/* Specific style for the End button text */
.call-action-text-end {
  color: #e53e3e; /* Red text for End button */
}
</style>
