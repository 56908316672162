<template>
  <div>
    <footer class="bg-white mt-8 mb-5">
      <div class="max-w-full md:max-w-[960px] mx-auto px-4 md:px-10">
        <div class="flex justify-center gap-4 mb-6">
          <LanguageSwitcher class="mr-1" />
        </div>

        <!-- Footer Links -->
        <div class="flex flex-col sm:flex-row justify-center gap-6 mb-6">
          <NuxtLink
            href="/"
            class="text-slate-500 text-xs font-normal text-center"
          >
            {{ $t('footer.howItWorks') }}
          </NuxtLink>
          <NuxtLink
            to="/about"
            class="text-slate-500 text-xs font-normal text-center"
          >
            {{ $t('footer.aboutUs') }}
          </NuxtLink>
          <a
            href="mailto: support@petcall.io"
            class="text-slate-500 text-xs font-normal text-center"
          >
            {{ $t('footer.contact') }}
          </a>
          <NuxtLink
            to="/privacy-policy"
            class="text-slate-500 text-xs font-normal text-center"
          >
            {{ $t('footer.privacyPolicy') }}
          </NuxtLink>
          <NuxtLink
            to="/tos"
            class="text-slate-500 text-xs font-normal text-center"
          >
            {{ $t('footer.termsOfService') }}
          </NuxtLink>
        </div>

        <!-- Social Icons -->
        <!-- <div class="flex justify-center gap-4 mb-6">
          <div class="w-4 h-4">
            <img
              src="public/images/facebook.svg"
              :alt="$t('footer.socialMedia.facebook')"
            />
          </div>
          <div class="w-4 h-4">
            <img
              src="public/images/twitter.svg"
              :alt="$t('footer.socialMedia.twitter')"
            />
          </div>
          <div class="w-4 h-4">
            <img
              src="public/images/instagram.svg"
              :alt="$t('footer.socialMedia.instagram')"
            />
          </div>
        </div> -->

        <!-- Copyright Notice -->
        <div class="text-center text-slate-500 text-xs font-normal">
          {{ $t('footer.copyright') }}
        </div>
      </div>
    </footer>
  </div>
</template>
