<template>
  <div>
    <!-- Overlay -->
    <div
      v-if="open"
      class="fixed inset-0 bg-black bg-opacity-50 z-10 transition-opacity md:hidden"
      @click="toggleMenu"
    />

    <div
      class="max-w-full md:max-w-[960px] mx-auto px-6 sm:px-10 py-3 border-b border-gray-200 flex justify-between items-center"
    >
      <!-- Logo -->
      <div class="flex items-center gap-4">
        <button class="z-30 md:hidden" @click="open = !open">
          <!-- Hamburger Icon -->
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
        <div>
          <NuxtLink :to="isAuthenticated ? '/welcome' : '/'">
            <!-- image -->
            <img
              src="public/images/brand/logo-text-medgreen.png"
              alt="PetCall Logo"
              class="w-20"
            />
          </NuxtLink>
        </div>
      </div>

      <!-- Navigation Links and Buttons -->
      <div class="flex items-center justify-between">
        <nav class="flex gap-6 items-center">
          <!-- float these buttons to the end -->
          <div class="ml-auto" />

          <NuxtLink
            v-if="!isAuthenticated"
            to="/"
            class="text-sm font-normal text-neutral-900 hover:underline hidden md:inline"
            >{{ $t('header.howItWorks') }}</NuxtLink
          >
          <NuxtLink
            v-if="!isAuthenticated"
            to="/about"
            class="text-sm font-normal text-neutral-900 hover:underline hidden md:inline"
            >{{ $t('header.whyPetCall') }}</NuxtLink
          >

          <NuxtLink
            to="/veterinarians"
            class="text-sm font-normal text-neutral-900 hover:underline hidden md:inline"
            >{{ $t('header.veterinarians') }}</NuxtLink
          >

          <NuxtLink
            v-if="isAuthenticated"
            to="/meetings"
            class="text-sm font-normal text-neutral-900 hover:underline hidden md:inline"
            >{{ $t('header.meetings') }}</NuxtLink
          >

          <LanguageSwitcher class="inline mr-2 sm:mr-6" />

          <NuxtLink
            v-if="isAuthenticated"
            to="/pets"
            class="text-sm font-normal text-neutral-900 hover:underline"
          >
            <img
              src="public/images/buttons/paws.png"
              alt="My Pets"
              class="w-6 h-6 hover:opacity-80 rounded-full border-2 border-secondary p-1"
            />
          </NuxtLink>

          <Menu
            v-if="isAuthenticated"
            as="div"
            class="relative inline-block text-left"
          >
            <MenuButton
              class="flex items-center text-sm font-normal text-secondary hover:text-secondary-light"
            >
              <UserIcon
                class="h-7 w-7 hover:opacity-80 rounded-full border-2 border-secondary"
                aria-hidden="true"
              />
            </MenuButton>

            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems
                class="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-50"
              >
                <div class="px-1 py-1">
                  <MenuItem v-slot="{ active }">
                    <button
                      :class="[
                        active ? 'bg-secondary text-white' : 'text-gray-900',
                        'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                      ]"
                      @click="navigateAndClose('/profile')"
                    >
                      <span
                        :active="active"
                        class="mr-2 h-5 w-5 text-secondary"
                        aria-hidden="true"
                      />
                      {{ $t('header.myAccount') }}
                    </button>
                  </MenuItem>
                </div>
                <div class="px-1 py-1">
                  <MenuItem v-slot="{ active }">
                    <button
                      :class="[
                        active
                          ? 'bg-secondary-light text-white'
                          : 'text-gray-900',
                        'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                      ]"
                      @click="logOut"
                    >
                      <span class="mr-2 h-5 w-5 text-secondary" />
                      {{ $t('header.logOut') }}
                    </button>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </nav>

        <div class="flex gap-2 ml-auto">
          <!-- <LanguageSwitcher
            v-if="!isAuthenticated"
            class="mx-1 sm:mx-2 inline"
          /> -->
          <NuxtLink v-if="!isAuthenticated" to="/signup">
            <button
              class="px-2 py-2 bg-primary text-white text-xs rounded-xl hover:bg-primary-light"
            >
              {{ $t('header.signUp') }}
            </button>
          </NuxtLink>
          <NuxtLink v-if="!isAuthenticated" to="/login">
            <button
              class="px-2 py-2 bg-secondary text-white text-xs rounded-xl hover:bg-secondary-light"
            >
              {{ $t('header.logIn') }}
            </button>
          </NuxtLink>
        </div>
      </div>
    </div>

    <!-- Slide-out Menu Below Navigation Header -->
    <transition
      name="slide-out-menu"
      enter-active-class="ease-out duration-300"
      leave-active-class="ease-in duration-300"
    >
      <div
        v-if="open"
        class="slide-out-menu fixed md:relative top-[65px] inset-x-0 w-full md:w-auto bg-white z-20"
      >
        <!-- Close Icon at the top right corner -->
        <button class="absolute top-0 right-0 m-4" @click="toggleMenu">
          <svg
            class="h-6 w-6 text-gray-700"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <nav class="flex flex-col p-4">
          <NuxtLink
            to="/veterinarians"
            class="mb-4 hover:underline hover:text-secondary"
            @click="toggleMenu"
            >{{ $t('header.veterinarians') }}</NuxtLink
          >
          <NuxtLink
            v-if="isAuthenticated"
            to="/meetings"
            class="mb-4 hover:underline hover:text-secondary"
            @click="toggleMenu"
            >{{ $t('header.meetings') }}</NuxtLink
          >
          <NuxtLink
            v-if="isAuthenticated"
            to="/pets"
            class="mb-4 hover:underline hover:text-secondary"
            @click="toggleMenu"
            >{{ $t('header.myPets') }}</NuxtLink
          >
          <NuxtLink
            href="/"
            class="mb-4 hover:underline hover:text-secondary"
            @click="toggleMenu"
            >{{ $t('header.howItWorks') }}</NuxtLink
          >
          <NuxtLink
            to="/about"
            class="mb-4 hover:underline hover:text-secondary"
            @click="toggleMenu"
            >{{ $t('header.whyPetCall') }}</NuxtLink
          >
          <NuxtLink
            v-if="isAuthenticated"
            class="mb-4 hover:underline cursor-pointer text-red-400/90 hover:text-red-600"
            @click="logOut"
            >{{ $t('header.logOut') }}</NuxtLink
          >
          <NuxtLink
            v-if="!isAuthenticated"
            to="/signup"
            class="mb-4 hover:underline hover:text-secondary"
            @click="toggleMenu"
            >{{ $t('header.signUp') }}</NuxtLink
          >
          <NuxtLink
            v-if="!isAuthenticated"
            to="/login"
            class="mb-4 hover:underline hover:text-secondary"
            @click="toggleMenu"
            >{{ $t('header.logIn') }}</NuxtLink
          >
        </nav>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useRouter } from 'vue-router'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { UserIcon } from '@heroicons/vue/20/solid'

const router = useRouter()
const authStore = useAuthStore()

const open = ref(false)

// Method to toggle the menu open/closed
const toggleMenu = () => {
  open.value = !open.value
}
const isAuthenticated = computed(() => authStore.isAuthenticated)

// Method to sign out the user
const logOut = async () => {
  await authStore.logout()
  closeDropdown()
  router.push({ name: 'login' })
}

const closeDropdown = () => {
  open.value = false
}

const navigateAndClose = (path) => {
  closeDropdown()
  nextTick(() => {
    router.push(path)
  })
}
</script>

<style scoped>
/* Slide-out menu transition styles */
.slide-out-menu-enter-active,
.slide-out-menu-leave-active {
  transition:
    transform 0.3s,
    opacity 0.3s;
}
.slide-out-menu-enter-from,
.slide-out-menu-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}
.slide-out-menu-enter-to,
.slide-out-menu-leave-from {
  opacity: 1;
  transform: translateX(0);
}

.fixed-action-bar {
  background-color: #ffffff; /* White background */
  padding: 1rem; /* Padding around the buttons */
  box-shadow:
    0 -4px 6px -1px rgba(0, 0, 0, 0.1),
    0 -2px 4px -1px rgba(0, 0, 0, 0.06); /* Shadow on top */
}

/* Style for the call action buttons */
.call-action-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Style for icons */
.call-action-icon {
  width: 3rem; /* 48px */
  height: 3rem; /* 48px */
}

/* Style for the text under icons */
.call-action-text {
  margin-top: 0.5rem; /* 8px */
  font-size: 0.75rem; /* 12px */
  color: #4a5568; /* Gray text */
}

/* Specific style for the End button text */
.call-action-text-end {
  color: #e53e3e; /* Red text for End button */
}
</style>
