<template>
  <Menu as="div" class="relative inline-block text-left">
    <div v-if="locale">
      <MenuButton
        class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-1 py-2 text-sm text-gray-900 ring-gray-300 hover:bg-gray-50"
      >
        <GlobeAltIcon class="-mr-1 h-4 w-4 pt-1" aria-hidden="true" />
        <span class="-mr-1">{{ locale.toUpperCase() }}</span>
        <ChevronDownIcon
          class="-mr-1 h-4 w-4 text-gray-400"
          aria-hidden="true"
        />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="py-1">
          <MenuItem>
            <a
              href="#"
              class="flex justify-between hover:bg-blue-200/50"
              :class="[
                locale === 'en' ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm',
              ]"
              @click="setLocale('en')"
            >
              <div class="flex">
                <span class="border-r-2 border-gray-200 pr-2 mr-2">EN</span>
                <p>English</p>
              </div>
              <div>
                <CheckIcon
                  v-if="locale === 'en'"
                  class="-mr-1 h-4 w-4 text-gray-400"
                />
              </div>
            </a>
          </MenuItem>
          <MenuItem>
            <a
              href="#"
              class="flex justify-between hover:bg-blue-200/50"
              :class="[
                locale === 'th' ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm',
              ]"
              @click="setLocale('th')"
            >
              <div class="flex">
                <span
                  aria-hidden="true"
                  class="border-r-2 border-gray-200 pr-2 mr-2"
                  >TH</span
                >
                <p>Thai</p>
              </div>
              <div>
                <CheckIcon
                  v-if="locale === 'th'"
                  class="-mr-1 h-4 w-4 text-gray-400"
                />
              </div>
            </a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import {
  ChevronDownIcon,
  GlobeAltIcon,
  CheckIcon,
} from '@heroicons/vue/20/solid'
const { locale, setLocale } = useI18n()
</script>
