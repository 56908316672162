<template>
  <div>
    <LayoutVetHeader v-if="isSignedInVet" />
    <LayoutMainHeader v-else />
    <div
      class="alert-container fixed top-2 left-1/2 transform -translate-x-1/2 flex flex-col items-center gap-2"
    >
      <MiscAlert
        v-for="alert in alerts"
        :key="alert.id"
        :visible="alert.visible"
        :message="alert.message"
        :type="alert.type"
        @update:visible="handleVisibilityChange(alert.id)"
      />
    </div>
    <NuxtPage />
    <LayoutMainFooter v-if="!hideFooter" />
  </div>
</template>

<script setup>
import { useAlertStore } from '@/stores/alert'
import { useAuthStore } from '@/stores/auth'
import { useRoute } from 'vue-router'

const { alerts, removeAlert } = useAlertStore()
const authStore = useAuthStore()
const route = useRoute()

const isSignedInVet = computed(
  () =>
    authStore.isAuthenticated &&
    authStore.user[
      'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
    ] === 'Veterinarian',
)

function handleVisibilityChange(id) {
  removeAlert(id)
}

const hideFooter = computed(() => {
  const pagesWithoutFooter = [
    /^\/vet\/meetings\/[0-9a-fA-F-]{36}$/, // Regular expression for paths with UUIDs
  ]
  return pagesWithoutFooter.some((regex) => regex.test(route.path))
})
</script>

<style scoped>
.alert-container {
  z-index: 1050;
  max-width: 512px;
  min-width: 300px;
}
</style>
